import debug from 'debug'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import DevOnly from 'src/components/dev/dev-only'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { CancelButton, NextButton, PreviousButton } from 'src/components/flows/step-buttons'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import MonacoEditor from 'src/components/inputs/monaco-editor'
import { testSchemas } from 'src/components/schema-designer/debug-test-schema'
import { SchemaDesigner } from 'src/components/schema-designer/schema-designer'
import { SchemaDesignerContext, useSchemaDesignerMode } from 'src/components/schema-designer/schema-designer-context'
import { ToggleDesignerButton } from 'src/components/schema-designer/toggle-designer-button'
import { VendiaSchema } from 'src/types/schema'
import notify from 'src/utils/notify'
import { validateSchema } from 'src/utils/validate-schema'

import ImportGraphQLDrawer from '../import-graphql-drawer'
import { UniCreateFormValues } from './standard.flow'

export const logger = debug('app:StepSchemaDesigner')

export const SchemaDesignerHeader = () => {
  const { setDesignerState, schema, selectedEntityKey, selectedEntityFieldKeys, showJsonEditor } =
    useContext(SchemaDesignerContext)

  logger('SchemaDesignerHeader', { schema, selectedEntityKey, selectedEntityFieldKeys, showJsonEditor })

  const headerActions = (
    <div className='flex'>
      <ToggleDesignerButton />
    </div>
  )

  const loadTestSchemaButton = (
    <DevOnly>
      <Button
        kind='link'
        className='ml-4 opacity-20'
        onClick={() => {
          setDesignerState({ schema: testSchemas[0] as unknown as VendiaSchema, selectedEntityKey: null })
        }}
      >
        DEBUG: LOAD TEST SCHEMA
      </Button>
    </DevOnly>
  )

  if (showJsonEditor) {
    return (
      <StepContentHeader
        actions={headerActions}
        title={<>Edit your Uni's data model as JSON</>}
        description={
          <>
            Edit your data model here or use your favorite editor via copy/paste. Click <strong>Next</strong> when
            you're happy with your changes. Click <strong>Use Designer</strong> to use our visual editor.
          </>
        }
      />
    )
  }

  if (selectedEntityKey) {
    return (
      <StepContentHeader
        actions={headerActions}
        title={
          selectedEntityFieldKeys.length ? (
            <span className='font-normal'>
              Editing attributes for{' '}
              <span className='font-bold'>{schema?.properties[selectedEntityKey]?.title || selectedEntityKey}</span>
              {loadTestSchemaButton}
            </span>
          ) : (
            <span className='font-normal'>
              Next, let's add attributes to the{' '}
              <span className='font-bold'>{schema?.properties[selectedEntityKey]?.title || selectedEntityKey}</span>{' '}
              entity.
              {loadTestSchemaButton}
            </span>
          )
        }
        description={
          selectedEntityFieldKeys.length ? (
            <span>
              You can add, edit, or delete attributes for this entity. Add additional entities by clicking the{' '}
              <span className='font-bold'>Add entity</span> button below. When you're happy with your data model, click{' '}
              <span className='font-bold'>Next</span> to continue.
            </span>
          ) : (
            <span>
              Attributes are the various properties of your entity. For example, a <strong>Customer</strong> entity
              might have <strong>name</strong>, <strong>address</strong>, and <strong>phone number</strong> attributes.
            </span>
          )
        }
      />
    )
  }

  return (
    <StepContentHeader
      actions={headerActions}
      title={<>Add entities to your Uni's data model{loadTestSchemaButton}</>}
      description={
        <>
          Let's create our first <strong>entity</strong>. Click the <strong>Add entity</strong> button to the left.
        </>
      }
    />
  )
}

export const SchemaDesignerMain = () => {
  const { setDesignerState, tempJsonEditorSchema, showJsonEditor } = useContext(SchemaDesignerContext)
  const [isImportGraphQLOpen, setIsImportGraphQLOpen] = useState(false)

  const onGraphQLSchemaImported = (jsonSchema: VendiaSchema) => {
    const schemaEditor = window.monaco?.editor?.getModels?.()?.[0]
    if (schemaEditor) {
      const schemaString = JSON.stringify(jsonSchema, null, 2)
      schemaEditor.setValue(schemaString)
      setDesignerState({ tempJsonEditorSchema: schemaString, uniSchema: jsonSchema })
      setIsImportGraphQLOpen(false)
    } else {
      notify.error('Unable to convert GraphQL Schema to JSON Schema.')
    }
  }

  if (!Boolean(tempJsonEditorSchema)) {
    return null
  }

  if (showJsonEditor) {
    return (
      <div className='relative w-full pt-4'>
        <div className='absolute right-6 top-6 flex w-full items-end justify-end'>
          {/* Shoving this in top-right corner of editor for now (like 'copy schema' button in read-only view) */}
          <Button kind='link' className='z-10 !bg-white !p-1' onClick={() => setIsImportGraphQLOpen(true)}>
            Import GraphQL Schema
          </Button>
        </div>
        <MonacoEditor
          minHeight={200}
          className='mb-8 rounded-md border border-black p-1 py-6'
          language='json'
          src={tempJsonEditorSchema as string}
          readOnly={false}
          data-testid='schema-editor'
          onChange={(value) => {
            setDesignerState({ tempJsonEditorSchema: value })
          }}
        />
        <ImportGraphQLDrawer
          isOpen={isImportGraphQLOpen}
          onClose={() => setIsImportGraphQLOpen(false)}
          onSchemaChange={onGraphQLSchemaImported}
        />
      </div>
    )
  }

  return <SchemaDesigner />
}

export const SchemaDesignerButtons: StepComponent<UniCreateFormValues> = ({ context }) => {
  const { setDesignerState, showJsonEditor, tempJsonEditorSchema, schema, selectedEntityKey } =
    useContext(SchemaDesignerContext)
  const navigate = useNavigate()

  return (
    <StepButtonsWrapper>
      <div className='flex gap-4'>
        <PreviousButton
          onClick={async () => {
            if (showJsonEditor) {
              setDesignerState({ tempJsonEditorSchema: JSON.stringify(schema, null, 2) })
            }
            context.goPrevious()
          }}
        />
        <CancelButton onClick={() => navigate('/')} />
      </div>
      <NextButton
        onClick={async () => {
          if (showJsonEditor) {
            // When editing in JSON editor we don't let them navigate forward with errors, show error message
            const [error, validSchema] = await validateSchema(tempJsonEditorSchema)
            logger('validated schema', { error, validSchema })
            if (error) {
              return notify.error(
                `Please address the following problems with your schema before continuing:<br/><br/>${error}`,
              )
            } else {
              setDesignerState({ schema: validSchema })
            }
          } else {
            // Check for empty entities and just remove them from the schema here
            // TODO: better to pop up modal and confirm this is a good thing to do
            Object.keys(schema!.properties).forEach((entityKey) => {
              const entity = schema?.properties[entityKey]
              if (
                (entity?.type === 'object' && (!entity?.properties || !Object.keys(entity?.properties ?? {}).length)) ||
                (entity?.type === 'array' &&
                  entity?.items?.type === 'object' &&
                  (!entity?.items?.properties || !Object.keys(entity?.items?.properties ?? {}).length))
              ) {
                notify.success(`Removed empty entity ${entityKey} from your schema.`)
                delete schema!.properties[entityKey]
                setDesignerState({ schema })
                if (entityKey === selectedEntityKey) {
                  setDesignerState({ selectedEntityKey: null })
                }
              }
            })

            // When editing in designer, we should provide nice guidance for errors, this is a last ditch effort to catch
            // anything we don't have nice support for yet before proceeding to next step
            const [error] = await validateSchema(JSON.stringify(schema))
            if (error) {
              return notify.error(
                `Please address the following problems with your schema before continuing:<br/><br/>${error}`,
              )
            }
          }

          context.goNext()
        }}
      />
    </StepButtonsWrapper>
  )
}

export const StepSchemaDesigner = (props: StepComponentProps<UniCreateFormValues>) => {
  useSchemaDesignerMode()

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-col items-center p-4 lg:px-8'>
          <SchemaDesignerHeader />
          <SchemaDesignerMain />
        </div>
      </ScrollableStepContent>
      <SchemaDesignerButtons {...props} />
    </StepWrapper>
  )
}
