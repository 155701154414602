import { Description, Label, Radio, RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import Balancer from 'react-wrap-balancer'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { CancelButton, NextButton, PreviousButton } from 'src/components/flows/step-buttons'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import Pill from 'src/components/pills/pill'
import { SchemaDesignerContext, useSchemaDesignerMode } from 'src/components/schema-designer/schema-designer-context'
import { getEmptySchema } from 'src/components/schema-designer/utils'

import { templates } from '../templates'

const logger = debug('app:selectEditMode')

const SCHEMA_DESIGNER = 'Schema Designer'
const JSON_EDITOR = 'JSON Editor'

function RadioOption({
  checked,
  name,
  description,
  icon,
  isTemplate = false,
}: {
  checked: boolean
  name: string
  description: string
  icon: string
  isTemplate?: boolean
}) {
  const wrapperClasses = clsx(!checked && 'ring-neutral-4 ring-2', checked && 'ring-information-9 bg-neutral-0 ring-4')
  const iconClasses = clsx(!isTemplate && 'h-24 w-24', isTemplate && 'h-16 w-16')

  return (
    <div className={`relative grid h-full place-content-center rounded-xl p-6 ring-inset ${wrapperClasses}`}>
      {name === SCHEMA_DESIGNER && (
        <div className='absolute right-4 top-4'>
          <Pill kind='info' className='ml-2'>
            Recommended
          </Pill>
        </div>
      )}
      <Label className={`relative flex flex-col items-center justify-center`}>
        <div className={iconClasses}>
          <img className={iconClasses} src={`/images/${icon}`} alt={icon} />
        </div>
        <div className='flex items-center'>
          <div className={`mt-2 flex items-baseline font-semibold ${!isTemplate ? 'mb-1 text-xl' : ''}`}>{name}</div>
        </div>
        <Description className={'text-neutral-8 text-xs'}>
          <Balancer>{description}</Balancer>
        </Description>
        <div className='mt-4'>
          {checked ? (
            <div className='ring-information-9 grid h-8 w-8 place-content-center rounded-full ring-2 ring-inset'>
              <div className='bg-information-9 h-4 w-4 rounded-full' />
            </div>
          ) : (
            <div className={`ring-information-9 h-8 w-8 rounded-full ring-2 ring-inset`} />
          )}
        </div>
      </Label>
    </div>
  )
}

export const StepSchemaEditMode: StepComponent = ({ context }) => {
  useSchemaDesignerMode()

  const navigate = useNavigate()
  const { setDesignerState, selectedDemoTemplate } = useContext(SchemaDesignerContext)
  logger('selectedDemoTemplate', selectedDemoTemplate)

  function handleChange(value: string) {
    const template = templates.find((t) => t.name === value)
    logger('handle change mode', { value, template })
    if (template) {
      setDesignerState({
        schema: JSON.parse(template.schema),
        tempJsonEditorSchema: template.schema,
        selectedDemoTemplate: value,
        selectedEntityKey: null,
      })
    }

    const schema = getEmptySchema()
    const tempJsonEditorSchema = JSON.stringify(schema, null, 2)
    if (value === SCHEMA_DESIGNER) {
      setDesignerState({
        schema,
        tempJsonEditorSchema,
        showJsonEditor: false,
        selectedDemoTemplate: SCHEMA_DESIGNER,
        selectedEntityKey: null,
      })
    }
    if (value === JSON_EDITOR) {
      setDesignerState({
        schema,
        tempJsonEditorSchema,
        showJsonEditor: true,
        selectedDemoTemplate: JSON_EDITOR,
        selectedEntityKey: null,
      })
    }
  }

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-1 flex-col items-center p-4 lg:px-8'>
          <StepContentHeader
            title='How would you like to design your data model?'
            description={'Select an option and begin designing your data model. You can always change this later.'}
          />
          <RadioGroup
            value={selectedDemoTemplate}
            onChange={handleChange}
            className={'flex w-full flex-1 flex-col px-16'}
          >
            <div className='my-3 w-full font-bold'>Select the option that works best for you</div>
            <div className={'flex h-72 gap-8'}>
              <Radio value={SCHEMA_DESIGNER} className={'w-1/2'}>
                {({ checked }) => (
                  <RadioOption
                    checked={checked}
                    name={SCHEMA_DESIGNER}
                    description={'Use our visual schema designer to build your data model. Supports CSV upload.'}
                    icon={'upload-spreadsheet.svg'}
                  />
                )}
              </Radio>
              <Radio value={JSON_EDITOR} className={'w-1/2'}>
                {({ checked }) => (
                  <RadioOption
                    checked={checked}
                    name={JSON_EDITOR}
                    description={'Work directly with JSON to create your data model'}
                    icon={'laptop-spreadsheet.svg'}
                  />
                )}
              </Radio>
            </div>
            <div className='mb-3 mt-8 w-full font-bold'>Or explore and learn using one of our demo templates</div>
            <div className={'h48 flex gap-8'}>
              {templates.slice(0, 3).map((template) => (
                <Radio key={template.name} value={template.name} className={'w-1/3'}>
                  {({ checked }) => (
                    <RadioOption
                      isTemplate
                      checked={checked}
                      name={template.name}
                      description={template.description}
                      icon={template.icon}
                    />
                  )}
                </Radio>
              ))}
            </div>
          </RadioGroup>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <div className='flex gap-4'>
          <PreviousButton onClick={context.goPrevious} />
          <CancelButton onClick={() => navigate('/')} />
        </div>
        <NextButton onClick={context.goNext} />
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
