import clsx from 'clsx'
import debug from 'debug'
import Card, { CardRule } from 'src/components/containers/card'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { ProductPolicies } from 'src/components/lakehouse/product-policies'
import { LakehouseFlowStepValues, LakehouseProduct } from 'src/utils/lakehouse/types'

import { lakehouseRegionOptions, snowflakeToAwsRegionMap } from '../utils'

const logger = debug('app:reviewSharingPermissions')

export const StepReviewSharingPermissions: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form

  const { source, product, flowSteps, currStepIndex, currStep } = form.useStore((state) => state.values)

  logger('state', {
    source,
    product,
    flowSteps,
    currStepIndex,
    currStep,
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Review sharing permissions'
        description='Review the data product your partner(s) will receive and access.'
      />
      <ScrollableStepContent inset>
        <div className='w-full max-w-7xl'>
          <div className='flex gap-6 p-6'>
            {source ? (
              <div className='w-1/3'>
                <Card className='w-full'>
                  <div className='flex flex-col gap-4'>
                    <div className='flex justify-between pb-2 text-sm font-bold'>
                      <div>Source</div>
                      <div>{snowflakeToAwsRegionMap[source.sourceRegion]}</div>
                    </div>
                    <CardRule className='-mx-10' />
                    <div className='flex flex-col gap-2 pb-2'>
                      <div className='flex flex-col gap-4'>
                        <div className='flex items-center justify-between'>
                          <h5 className='text-neutral-9 text-xs font-bold'>Data source</h5>
                        </div>
                        {source.connection === 'snowflake' && (
                          <div className='flex items-center gap-4'>
                            <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-10' />
                            <div className='flex flex-col gap-0.5'>
                              <div className='font-bold'>Snowflake</div>
                              <div className='text-neutral-8 text-xs'>{source.selectedTable}</div>
                            </div>
                          </div>
                        )}
                        {source.connection === 'cloudera' && (
                          <div className='flex items-center gap-4'>
                            <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-10' />
                            <div className='flex flex-col gap-0.5'>
                              <div className='font-bold'>Cloudera</div>
                              <div className='text-neutral-8 text-xs'>{source.selectedTable}</div>
                            </div>
                          </div>
                        )}
                      </div>
                      <hr className='border-neutral-2 my-4 border' />
                      <div className='flex flex-col gap-4'>
                        <h5 className='text-neutral-9 text-xs font-bold'>Selected Regions</h5>
                        {product.regions.map((region) => {
                          const option = lakehouseRegionOptions.find((option) => option.value === region)
                          return (
                            option && (
                              <div>
                                <div className='flex items-baseline text-sm font-semibold'>{option?.label}</div>
                                <h2 className='text-neutral-8 text-xs'>{option?.description}</h2>
                              </div>
                            )
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ) : null}
            <div className={clsx('flex flex-col gap-4', source ? 'w-2/3' : 'w-full')}>
              <DataProductCard product={product} />
            </div>
          </div>
        </div>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.isSubmitting]}>
        {([isSubmitting]) => (
          <StepButtons context={context} isLastStep isSubmitting={isSubmitting} onNext={context.handleSubmit} />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}

const DataProductCard = ({ product }: { product: LakehouseProduct }) => {
  const regions = lakehouseRegionOptions.filter((opt) => product.regions.includes(opt.value))

  return (
    <Card className='w-full' padding='none'>
      <div className='flex w-full flex-col justify-between gap-6 p-6'>
        <div className='flex flex-grow flex-col gap-4'>
          <div className='flex items-center gap-6'>
            <img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />
            <div className='flex flex-col gap-1'>
              <div className='text-lg font-bold'>{product.name}</div>
              <div className='text-neutral-10 text-balance text-xs'>{regions.map((r) => r.label).join(', ')}</div>
            </div>
          </div>
        </div>
        <CardRule />
        <ProductPolicies product={product} />
      </div>
    </Card>
  )
}
