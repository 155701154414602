import { useQuery } from '@tanstack/react-query'
import { ShareApp } from '@vendia/management-api-types'
import { useRecoilValue } from 'recoil'
import useApi from 'src/utils/hooks/use-api'

import { selectedRoleState } from '../state'

export const useGetShareApps = ({ uniName }: { uniName?: string }) => {
  const api = useApi()
  const selectedRoleName = useRecoilValue(selectedRoleState).name

  return useQuery({
    queryKey: ['getShareApps', selectedRoleName, uniName],
    queryFn: async () => {
      const { getShareApps, message, errors } = await api.getShareApps<{ getShareApps?: ShareApp[] }>({
        uniName,
        asRole: selectedRoleName,
      })
      if (message) {
        console.error(`Error fetching Share apps: ${message}`)
        return
      }
      if (errors) {
        console.error(`Could not fetch Share apps: ${errors[0].message}`)
        return
      }
      return getShareApps
    },
    // Similar to useListUnis, make sure we've finished initializing selected role before firing request.
    enabled: Boolean(api) && Boolean(uniName) && Boolean(selectedRoleName) && Boolean(uniName),
    staleTime: 1000 * 30, // 30 seconds, be a little more aggressive to get fresh deployment status
  })
}
