import { FieldApi, useField } from '@tanstack/react-form'
import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import clsx from 'clsx'
import debug from 'debug'
import { StepId } from 'src/pages/uni-create/config'
import { getValue } from 'src/utils/form/form'
import { isRequiredValidator } from 'src/utils/form/validation'
import { getColumnOptions, getColumnPermissionOptions } from 'src/utils/lakehouse/flows'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import Button from '../buttons/button'
import ListboxField from '../fields/listbox.field'
import TextField from '../fields/text.field'
import { StepComponentProps, VendiaFlowFormApi } from '../flows/types'
import Icon from '../icons/icon'

const logger = debug('app:sharingPermissions')

export const EditColumnPolicies = ({
  context,
  policiesForm,
  fieldPath,
}: StepComponentProps<LakehouseFlowStepValues> & { policiesForm: VendiaFlowFormApi<any>; fieldPath?: string }) => {
  const selectedTableColumns = context.form.useStore((state) => state.values.selectedTableColumns)
  const { columnPolicies, defaultColumnPolicyEffect } = policiesForm.useStore((state) =>
    fieldPath ? getValue(state.values, fieldPath) : state.values,
  )

  const field = useField<any, any>({
    name: fieldPath ? `${fieldPath}.columnPolicies` : 'columnPolicies',
    form: policiesForm,
    mode: 'array',
    defaultValue: columnPolicies,
  })

  const columnPermissionOptions = getColumnPermissionOptions(defaultColumnPolicyEffect)
  const snowflakeColumnOptions = getColumnOptions(selectedTableColumns, columnPolicies)

  async function addColumnPolicy(field: FieldApi<any, any>) {
    await field.validate('change')
    if (field.state.meta.errors?.length === 0) {
      field.pushValue({ name: '', effect: '', value: '' })
    }
  }

  logger('value from policiesForm', { columnPolicies, defaultColumnPolicyEffect })

  return (
    <div className='flex w-full flex-1 flex-col'>
      {columnPolicies?.length === 0 && (
        <>
          <h1 className='m-2 self-center text-xl'>Configure column permissions using policies.</h1>
          <h2 className='self-center text-lg'>
            By default, <strong>{defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude ? 'no' : 'all'}</strong>{' '}
            columns are included without defined policies.
          </h2>
        </>
      )}
      {columnPolicies?.length > 0 && (
        <div
          className={clsx(
            'mb-4 flex h-5/6 w-full max-w-7xl flex-col justify-center gap-3',
            // h.scroll entire rows area on smaller screens
            'min-w-[1100px]',
          )}
        >
          {columnPolicies?.map((policy: any, index: number) => {
            const fieldPrefix = fieldPath ? `${fieldPath}.columnPolicies.${index}` : `columnPolicies.${index}`
            logger('column policy', { fieldPrefix, index, policy })

            return (
              <div
                key={index}
                className={'bg-uibg-1 rounded-md border border-indigo-300 p-4'}
                data-testid={`column-sharing-policy-${index}`}
              >
                <div className='flex items-center justify-stretch gap-3'>
                  <div className='flex w-full items-center justify-items-stretch gap-4'>
                    <span className='text-neutral-8 self-center'>When</span>
                    <ListboxField
                      name={`${fieldPrefix}.name`}
                      label='Attribute'
                      options={snowflakeColumnOptions}
                      data-testid={`column-policy-attribute-select-${index}`}
                      validators={lakehouseFlowFieldValidator({
                        stepId: StepId.SharingPermissions,
                        validator: isRequiredValidator(),
                      })}
                      useNestedLabel
                      setWidthAccordingToOptions
                      wrapperClassName='!w-auto'
                      form={policiesForm}
                    />
                    <span className='text-neutral-8 self-center'>is present,</span>
                    <ListboxField
                      name={`${fieldPrefix}.effect`}
                      label='Action'
                      options={columnPermissionOptions}
                      data-testid={`column-policy-action-select-${index}`}
                      className='min-w-80'
                      wrapperClassName='!w-auto'
                      validators={lakehouseFlowFieldValidator({
                        stepId: StepId.SharingPermissions,
                        validator: isRequiredValidator(),
                      })}
                      useNestedLabel
                      form={policiesForm}
                    />
                    <policiesForm.Subscribe selector={(state) => getValue(state.values, fieldPrefix)}>
                      {(policy) => {
                        if (
                          [DataPolicyColumnEffect.MaskExceptLast, DataPolicyColumnEffect.Replace].includes(
                            policy?.effect,
                          )
                        ) {
                          return (
                            <>
                              <TextField
                                name={`${fieldPrefix}.value`}
                                label={
                                  policy.effect === DataPolicyColumnEffect.MaskExceptLast ? 'Constraint' : 'Replacement'
                                }
                                placeholder='Enter replacement value'
                                data-testid={`column-policy-value-input-${index}`}
                                className='min-w-20'
                                wrapperClassName='!w-auto'
                                validators={lakehouseFlowFieldValidator({
                                  stepId: StepId.SharingPermissions,
                                  listenTo: [`${fieldPrefix}.effect`],
                                  validator: ({ value }: any) => {
                                    if (
                                      policy.effect === DataPolicyColumnEffect.MaskExceptLast &&
                                      Number.isNaN(parseInt(value))
                                    ) {
                                      return 'Must be a number'
                                    }
                                  },
                                })}
                                useNestedLabel
                                form={policiesForm}
                              />
                              {policy.effect === DataPolicyColumnEffect.MaskExceptLast && (
                                <span className='text-neutral-8 self-center'>characters</span>
                              )}
                            </>
                          )
                        }
                      }}
                    </policiesForm.Subscribe>
                  </div>
                  <div className={'p-2'}>
                    <Button kind='link' icon='trash' onClick={() => field.removeValue(index)} />
                  </div>
                </div>
                {field.state.meta.errors?.length > 0 && (
                  <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                    <Icon name='error' size='xs' />
                    {field.state.meta.errors[0]}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
      <div className='flex w-full max-w-4xl pb-6'>
        <Button
          kind={'secondary'}
          onClick={(e) => {
            e.preventDefault()
            addColumnPolicy(field)
          }}
          icon='plus-m'
          iconSize={14}
        >
          {field.state.value.length ? 'Add another' : 'Add column policy'}
        </Button>
      </div>
    </div>
  )
}
