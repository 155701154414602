import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { SourceButton } from 'src/components/lakehouse/source-button'
import { isRequiredValidator } from 'src/utils/form/validation'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import { StepId } from '../config'

export const StepChooseSource: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form

  form.useField({
    name: 'source.connection',
    validators: lakehouseFlowFieldValidator<LakehouseFlowStepValues, any>({
      stepId: StepId.ChooseSource,
      validator: isRequiredValidator(),
    }),
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Choose your data source'
        description={'What data would you like to sync?'}
      />
      <ScrollableStepContent inset>
        <div className='mt-8 flex h-64 w-3/4 gap-4'>
          <SourceButton
            label='Snowflake'
            description='Connect to your data in Snowflake'
            imageSrc='/images/vendor/snowflake.png'
            context={context}
            connection='snowflake'
            to={StepId.ConnectSnowflake}
            linkText='Click here to connect'
          />
          <SourceButton
            label='Cloudera'
            description='Connect to your data in Cloudera'
            imageSrc='/images/vendor/cloudera.png'
            connection='cloudera'
            context={context}
            to={StepId.ConnectCloudera}
            message='Coming Soon!'
            linkText='Click here for more information'
            onClick={() => {
              window.location.href = 'mailto:support@vendia.com'
            }}
          />
        </div>
      </ScrollableStepContent>
      <StepButtons context={context} hasNext={false} />
    </StepWrapper>
  )
}
