import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import { useNavigate } from 'react-router'
import { LakehouseProduct } from 'src/utils/lakehouse/types'

import { ButtonIconAction } from '../buttons/button-icon-action'
import { ProductColumnPolicies } from './product-column-policies'
import { ProductRowPolicies } from './product-row-policies'

export const ProductPolicies = ({
  product,
  canEdit,
  lakehouseStatus,
}: {
  product: LakehouseProduct
  canEdit?: boolean
  lakehouseStatus?: boolean
}) => {
  const { columnPolicies, defaultColumnPolicyEffect } = product

  const allAllowed =
    columnPolicies?.every((p) => p.effect === DataPolicyColumnEffect.Allow) ||
    (columnPolicies.length === 0 && defaultColumnPolicyEffect === DataPolicyColumnEffect.Allow)
  const allExcluded = columnPolicies?.every((p) => p.effect === DataPolicyColumnEffect.Exclude)

  const navigate = useNavigate()

  return (
    <div className='bg-uibg-0 p-6'>
      <div className='flex flex-col gap-2'>
        <div className='mb-4 flex items-center justify-between'>
          <h1 className='font-bold'>Sharing rules applied</h1>
          <div>
            {canEdit && (
              <ButtonIconAction
                icon='pencil'
                label='Update data products'
                onClick={() => navigate('../edit-products')}
                disabled={lakehouseStatus}
              />
            )}
          </div>
        </div>
        <div className='flex flex-col gap-4 text-sm'>
          {(allAllowed || allExcluded) && (
            <div className='border-neutral-5 border bg-white p-4'>
              <strong>All columns</strong> are <strong>{allAllowed ? 'enabled' : 'excluded'}</strong>
            </div>
          )}
          <div>
            <h2 className='p-2 font-semibold'>Column policies</h2>
            <ProductColumnPolicies product={product} />
          </div>
          <div>
            <h2 className='p-2 font-semibold'>Row policies</h2>
            <ProductRowPolicies product={product} />
          </div>
        </div>
      </div>
    </div>
  )
}
