import { DataPolicyColumnEffect, LakehouseSyncScheduleEnum, ShareApp, Uni } from '@vendia/management-api-types'
import debug from 'debug'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import { StepId } from 'src/pages/uni-create/config'
import { StepCreateColumnSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-column-sharing-permissions.step'
import { CreateDataProductStep } from 'src/pages/uni-create/flow-lakehouse/create-data-product.step'
import { StepCreateRowSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-row-sharing-permissions.step'
import { StepReviewSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/review-sharing-permissions.step'
import { parsetLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import useApi from 'src/utils/hooks/use-api'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { generateShortId } from 'src/utils/short-id'

import { LakehouseDataLoader } from './lakehouse-loader'

const logger = debug('app:editDataProducts')

export const PageEditDataProducts = () => <LakehouseDataLoader Comp={PageEditDataProductsContent} />

export const PageEditDataProductsContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const api = useApi()
  const navigate = useNavigate()
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni.name })

  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion,
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parsetLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )
  // Single source for now & single table for now!
  const existingSources = shareAppConfig.sources
  const sourceConfig = existingSources[0]
  const sourceTable = sourceConfig.tableDefinitions[0]
  const selectedTableColumns = sourceTable.columns
  const existingProducts = shareAppConfig.products

  return (
    <MultiStepFlow<LakehouseFlowStepValues>
      initialFlowState={{
        flowMode: 'addProduct',
        product: {
          name: '',
          regions: [],
          columnPolicies: [],
          rowPolicies: [],
          defaultColumnPolicyEffect: DataPolicyColumnEffect.Exclude,
          syncSchedule: LakehouseSyncScheduleEnum.Daily,
        },
        selectedTableColumns,
      }}
      flowSteps={[
        {
          id: StepId.DataProducts,
          StepComponent: CreateDataProductStep,
        },
        {
          id: StepId.ColumnSharingPermissions,
          StepComponent: StepCreateColumnSharingPermissions,
        },
        {
          id: StepId.RowSharingPermissions,
          StepComponent: StepCreateRowSharingPermissions,
          showInStepNav: false,
        },
        {
          id: StepId.SharingPermissions,
          StepComponent: StepReviewSharingPermissions,
        },
      ]}
      onSubmit={async ({ value, formApi }) => {
        logger('submit', value)

        const { product } = value

        function distributionNodeNameFromRegion(region: string) {
          return `distribution-node-${region}`
        }

        // Keep track of shortIds to avoid collisions
        const existingIds = existingProducts.map((product) => product.key)
        // Build full product input from form data
        const shortId = generateShortId({ existingIds })
        existingIds.push(shortId)
        const newProductInput = {
          key: shortId,
          name: product.name,
          syncSchedule: product.syncSchedule,
          regions: product.regions,
          nodes: product.regions.map(distributionNodeNameFromRegion),
          tableDefinitions: [
            {
              tableName: sourceTable.tableName,
              columnPolicies: product.columnPolicies,
              rowPolicies: product.rowPolicies,
              defaultColumnPolicyEffect: product.defaultColumnPolicyEffect,
            },
          ],
        }

        // Get rid of connection stuff on source input for update resolver
        const sources = existingSources.map((source) => {
          return {
            type: source.type,
            tableDefinitions: source.tableDefinitions,
          }
        })
        const products = [...existingProducts, newProductInput]

        logger('products', products)

        const res = await api.updateLakehouseApp({
          input: {
            uniName: uni.name,
            products,
            sources,
          },
        })

        logger('res', res)
        refetchShareApps()
        navigate(`/uni/${uni.name}`)
      }}
    />
  )
}
