import debug from 'debug'
import { useNavigate } from 'react-router-dom'
import Card from 'src/components/containers/card'
import TextListField from 'src/components/fields/text-list.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { CancelButton, NextButton, PreviousButton } from 'src/components/flows/step-buttons'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import Loader from 'src/components/loaders/page-loader'
import inputValidation from 'src/utils/form/input.validation'
import { createOnBlurValidator } from 'src/utils/form/validation'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import useListUnis from 'src/utils/hooks/use-list-unis'

import { UniCreateFormValues } from './standard.flow'

const logger = debug('app:invite-partners')

export const StepInvitePartners: StepComponent<UniCreateFormValues> = ({ context }) => {
  const form = context.form
  const navigate = useNavigate()
  const { listUnisQuery } = useListUnis()
  const { data: unis } = listUnisQuery
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const userId = getCurrentVendiaUserQuery?.data?.getUser?.userId
  const currentStepIndex = form.useStore((s) => s.values.currStepIndex)

  if (!unis || !userId) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-col items-center p-4 lg:px-8'>
          <StepContentHeader
            className='max-w-4xl'
            title={
              <span>
                Invite partners to join your Uni<span className='text-neutral-7 font-normal'> (Optional)</span>
              </span>
            }
            description={`Up to 20 partners can join a Uni. You can invite additional partners at any time.`}
          />

          <div className={'mb-6 mt-12 flex w-full max-w-2xl flex-col gap-4'}>
            <Card padding='xl' onWhiteBg className={'flex w-full flex-col'}>
              <TextListField
                name='invites'
                fieldNameKey='email'
                addNewText='Add another partner'
                label='Partner email'
                defaultValue={['']}
                description='The email address of the partner you want to invite. You should use the email address they used to sign up for Vendia Share.'
                form={context.form}
                fieldValidators={createOnBlurValidator(inputValidation.isEmail)}
              />
            </Card>
          </div>
        </div>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.isSubmitting]}>
        {([isSubmitting]) => (
          <StepButtonsWrapper>
            <div className='flex gap-4'>
              {currentStepIndex !== 0 && <PreviousButton disabled={isSubmitting} onClick={context.goPrevious} />}
              <CancelButton onClick={() => navigate('/')} />
            </div>
            <NextButton
              label={isSubmitting ? 'Creating Uni...' : 'Finish'}
              className='min-w-[112px]' // Let it get wider when text updates to "Creating Uni..."
              onClick={form.handleSubmit}
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
            />
          </StepButtonsWrapper>
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
