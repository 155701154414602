import {
  CspLowerEnum,
  LakehouseDataProductInput,
  LakehouseDataSourceInput,
  NodeInput,
  SnowflakeTableColumnInput,
} from '@vendia/management-api-types'
import { s } from 'node_modules/vite/dist/node/types.d-aGj9QkWt'
import { AWS_REGIONS } from 'src/utils/csp/regions'
import { generateShortId } from 'src/utils/short-id'

// Check to see if a Snowflake type is a string type, allow string transformations, etc
export function isSnowflakeStringType(type: string) {
  return (
    type.toUpperCase().startsWith('CHAR') ||
    type.toUpperCase().startsWith('VARCHAR') ||
    type.toUpperCase().startsWith('STRING')
  )
}

export function snowflakeToJsonSchema(columns: SnowflakeTableColumnInput[]) {
  const properties: Record<string, any> = {}
  const jsonSchema = {
    type: 'object',
    properties,
    required: [],
    'x-vendia-unique': [],
  }

  const typeMapping: { [key: string]: string } = {
    NUMBER: 'number',
    FLOAT: 'number',
    INT: 'integer',
    INTEGER: 'integer',
    BIGINT: 'integer',
    SMALLINT: 'integer',
    TINYINT: 'integer',
    BYTEINT: 'integer',
    VARCHAR: 'string',
    CHAR: 'string',
    CHARACTER: 'string',
    STRING: 'string',
    TEXT: 'string',
    BOOLEAN: 'boolean',
    DATE: 'string',
    TIME: 'string',
    TIMESTAMP: 'string',
    TIMESTAMP_LTZ: 'string',
    TIMESTAMP_NTZ: 'string',
    TIMESTAMP_TZ: 'string',
    VARIANT: 'object',
    OBJECT: 'object',
    ARRAY: 'array',
  }

  columns.forEach((column) => {
    const columnName = column.name.toLowerCase()
    const snowflakeType = column.snowflakeType
    const jsonType = typeMapping[snowflakeType.split('(')[0].toUpperCase()] || 'string'

    jsonSchema.properties[columnName] = {
      type: jsonType,
      ['x-snowflake-type']: snowflakeType,
    }

    // Add format for date and time types
    if (snowflakeType.toUpperCase().startsWith('DATE')) {
      jsonSchema.properties[columnName].format = 'date'
    } else if (snowflakeType.toUpperCase().startsWith('TIMESTAMP')) {
      jsonSchema.properties[columnName].format = 'date-time'
    } else if (snowflakeType.toUpperCase().startsWith('TIME')) {
      jsonSchema.properties[columnName].format = 'time'
    }
  })
  return jsonSchema
}

// Trim, lowercase, replace whitespace with dashes, strip special characters, add random suffix
// Input: "My Uni Name 123!@#   "
// Output: "my-uni-name-123-4j5k"
export const buildUniNameFromAlias = (alias: string, randomSuffix: string) => {
  return alias
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9_-]/g, '')
    .toLowerCase()
    .substring(0, 20)
    .concat('-', randomSuffix)
}

export const buildUniFqn = (uniName: string, uniNamespace: string) => {
  if (!uniNamespace) {
    return `${uniName}.unis.vendia.net`
  }
  if (uniNamespace.split('.').filter((x) => x).length === 2) {
    return `${uniName}.unis${uniNamespace}`
  }
  return `${uniName}${uniNamespace}`
}

export function generateNodesForApi(
  userId: string,
  nodes: { name: string; description?: string; csp: CspLowerEnum; region: string }[],
) {
  // Lowercase here just in case we didn't lowercase in signup/invite flow
  userId = userId?.toLowerCase()

  return nodes.map(({ name, description, csp, region }) => {
    const nodeConfig: NodeInput = {
      name,
      description,
      userId,
      csp,
      region,
      settings: {
        apiSettings: {
          auth: {
            authorizerType: 'AUTHV2',
          },
        },
      },
    }

    switch (csp) {
      case 'aws':
        nodeConfig.settings.aws = {}
        break
      case 'azure':
        nodeConfig.settings.azure = {}
        break
      default:
        console.error('An unexpected error occured, please try again')
        break
    }

    return nodeConfig
  })
}

/* All AWS Snowflake regions to AWS regions
  AWS_US_WEST_2: us-west-2
  AWS_US_EAST_1: us-east-1
  AWS_AP_SOUTHEAST_2: ap-southeast-2
  AWS_EU_WEST_1: eu-west-1
  AWS_AP_SOUTHEAST_1: ap-southeast-1
  AWS_CA_CENTRAL_1: ca-central-1
  AWS_EU_CENTRAL_1: eu-central-1
  AWS_US_EAST_2: us-east-2
  AWS_AP_NORTHEAST_1: ap-northeast-1
  AWS_AP_SOUTH_1: ap-south-1
  AWS_EU_WEST_2: eu-west-2
  AWS_AP_NORTHEAST_2: ap-northeast-2
  AWS_EU_NORTH_1: eu-north-1
  AWS_AP_NORTHEAST_3: ap-northeast-3
  AWS_SA_EAST_1: sa-east-1
  AWS_EU_WEST_3: eu-west-3
  AWS_AP_SOUTHEAST_3: ap-southeast-3
  AWS_EU_CENTRAL_2: eu-central-2
*/
// TODO: support more regions?
export const snowflakeToAwsRegionMap: Record<string, string> = {
  AWS_US_WEST_2: 'us-west-2',
  AWS_US_EAST_1: 'us-east-1',
  AWS_US_EAST_2: 'us-east-2',
  AWS_EU_WEST_1: 'eu-west-1',
  AWS_EU_CENTRAL_1: 'eu-central-1',
}

export interface LakehouseExternalAccess {
  accessIdentifier: string
  status: 'PENDING_CONNECTION' | 'CONNECTION_REQUESTED' | 'CONNECTED' | 'CONNECTION_FAILED'
  productKey: string
  tableName: string
  grantedEmailAddress: string
  grantedEmailToken: string
  grantedEmailTokenTtl: string
  externalRoleArn?: string
  externalIds?: string[]
}

export interface LakehouseShareAppConfig {
  sources: LakehouseDataSourceInput[]
  products: LakehouseDataProductInput[]
  externalAccess?: LakehouseExternalAccess[]
}

export const parsetLakehouseShareAppConfig = (shareAppConfig: string): LakehouseShareAppConfig => {
  try {
    return JSON.parse(shareAppConfig) as LakehouseShareAppConfig
  } catch (e) {
    console.error('Error parsing Lakehouse share app config', e)
    return {
      sources: [],
      products: [],
    }
  }
}

export const lakehouseRegionOptions = AWS_REGIONS.map(({ value, label, displayAlt }) => ({
  value,
  label: displayAlt,
  description: label,
}))
