import { HTMLAttributes } from 'react'
import Card from 'src/components/containers/card'
import { FlowContext } from 'src/components/flows/types'
import { StepId } from 'src/pages/uni-create/config'

export type SourceButtonProps = HTMLAttributes<HTMLDivElement> & {
  imageSrc: string
  label: string
  description: string
  message?: string
  linkText?: string
  connection: string
  context: FlowContext<any>
  to: StepId
  disabled?: boolean
  onClick?: () => void
}

/* <Button kind='link' href='mailto:support@vendia.com' className='!text-base'></Button> */

export const SourceButton = ({
  imageSrc,
  label,
  description,
  context,
  connection,
  to,
  disabled,
  message,
  linkText,
  onClick,
  ...rest
}: SourceButtonProps) => {
  const form = context.form
  const source = form.useStore((s) => s.values.source)

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    // TODO: use a nicer modal here
    if (source.connection && source.connection !== connection) {
      const confirm = window.confirm(
        'Changing the source will erase any sharing policies you have already configured. Are you sure you want to continue?',
      )
      if (!confirm) {
        return
      }
      context.resetValidationErrors()
    }

    form.setFieldValue('source.connection', connection)
    form.setFieldValue(`source.${connection}`, {})
    setTimeout(() => context.goToStep(to))
  }

  return (
    <div className='flex w-1/2 items-center gap-4 place-self-stretch drop-shadow-md hover:drop-shadow-lg' {...rest}>
      <button onClick={onClick || handleClick} className='h-full w-full' disabled={disabled}>
        <Card className='h-full' ribbonMessage={message}>
          <div className='flex flex-col items-center py-4'>
            <div className='relative mb-4 rounded-full border-2 p-1'>
              <img src={imageSrc} alt={label} className='h-24 w-24 rounded-full' />
            </div>
            <h2 className='text-2xl font-bold'>{label}</h2>
            <p className='text-xs text-gray-500'>{description}</p>
            <p className='mt-4 text-xs font-bold text-blue-500 underline'>{linkText}</p>
          </div>
        </Card>
      </button>
    </div>
  )
}
