import { useForm } from '@tanstack/react-form'
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import { LakehouseDataProductInput, ShareApp, ShareAppStatus, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import { ContentWindow } from 'src/components/containers/content-window'
import Form from 'src/components/fields/form'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import Icon from 'src/components/icons/icon'
import { ListOption } from 'src/components/inputs/listbox.input'
import { ProductPolicies } from 'src/components/lakehouse/product-policies'
import Alert from 'src/components/messages/alert'
import ConnectionBanner from 'src/components/messages/connection-banner'
import HoverableTooltip from 'src/components/messages/hoverable-tooltip'
import ConfirmationModal from 'src/components/modals/confirmation-modal'
import BasicModal from 'src/components/modals/modal.basic'
import PageHeader from 'src/components/page-header'
import StatusPill from 'src/components/pills/status-pill'
import { SOURCE_NODE_NAME } from 'src/pages/uni-create/config'
import { parsetLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import { AWS_REGIONS } from 'src/utils/csp/regions'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useInterval } from 'src/utils/hooks/use-interval'
import { LakehouseProduct } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

import { LakehouseDataLoader } from './lakehouse-loader'
import { useLakehouseJobs } from './use-lakehouse-jobs'

dayjs.extend(relativeTime)

enum Status {
  Idle,
  ShareModalOpen,
}

export const PageLakehouseOverview = () => <LakehouseDataLoader Comp={PageLakehouseOverviewContent} />

export type ExecutionStatus = 'RUNNING' | 'SUCCEEDED' | 'FAILED' | 'TIMED_OUT' | 'ABORTED' | 'PENDING_REDRIVE'

const failureStatuses = ['FAILED', 'TIMED_OUT', 'ABORTED'] as ExecutionStatus[]

export interface Job {
  source: JobStatus
  regions: {
    [key: string]: JobStatus
  }
}

// Copy/pasted from API
export interface JobStatus {
  status: ExecutionStatus
  startTime: string
  stopTime: string
  error?: string
  errorType?: string
}

// organize executions by data product key
export interface JobsByDataProductKey {
  [key: string]: {
    source: JobStatus
    regions: {
      [key: string]: JobStatus
    }
  }[]
}

const PageLakehouseOverviewContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const api = useApi()
  const [selectedProductToShare, setSelectedProductToShare] = useState<string | null>(null)

  // While deploying, poll for updates to we can refresh the UI
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni.name })
  const anyShareAppsDeploying = shareApps.some((shareApp) =>
    [ShareAppStatus.Pending, ShareAppStatus.Deploying].includes(shareApp.shareAppStatus),
  )

  const lakehouseJobsQuery = useLakehouseJobs({ uniName: uni.name, enabled: !anyShareAppsDeploying })

  useInterval(() => {
    if (anyShareAppsDeploying) {
      refetchShareApps()
    }
  }, 30 * 1000)

  // Refetch lakehouse jobs when share apps are done deploying or they'll continue to display "loading..."
  const prevAnyShareAppsDeploying = useRef(anyShareAppsDeploying)
  useEffect(() => {
    if (prevAnyShareAppsDeploying.current && !anyShareAppsDeploying) {
      lakehouseJobsQuery.refetch()
    }
    prevAnyShareAppsDeploying.current = anyShareAppsDeploying
  }, [anyShareAppsDeploying])

  const uniAlias = uni.alias
  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion,
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parsetLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )

  // Single source for now & single table for now!
  const sourceConfig = shareAppConfig.sources[0]
  const sourceTableName = sourceConfig.tableDefinitions[0].tableName
  const sourceType = shareAppConfig.sources[0]?.type // SNOWFLAKE or CLOUDERA

  const sourceNode = uni.nodes?.find((node) => node.name === SOURCE_NODE_NAME)
  const sourceNodeRegion = sourceNode?.region
  const totalRegions = new Set(uni.nodes?.map((node) => node.region)).size

  const lakehouseNotReady = uni.status !== 'RUNNING' || anyShareAppsDeploying

  const getSnowflakeConnectionStatus = useQuery({
    queryKey: ['getSnowflakeConnectionStatus', uni?.name, sourceNode?.name],
    queryFn: async () => {
      const response = await api.getSnowflakeConnectionStatus({
        uniName: uni?.name,
        nodeName: sourceNode?.name,
      })

      if (response.message) {
        throw new Error(`Connection is not valid: ${response.message}`)
      }
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      if (!response.getSnowflakeConnectionStatus) {
        throw new Error('Connection is not valid')
      }

      return response.getSnowflakeConnectionStatus
    },
    enabled: !lakehouseNotReady && sourceType === 'SNOWFLAKE', // Prevent firing off before the secret is created and for other sources
    staleTime: 1000 * 60 * 15, // 15 minutes
  })

  // TODO: Turn this into isConnectionValid when we support checking and updating other source connections
  const isSnowflakeConnectionValid =
    sourceType === 'SNOWFLAKE' && getSnowflakeConnectionStatus?.data === 'Snowflake connection is valid'

  const [status, setStatus] = useState(Status.Idle)

  const shareDataProduct = useMutation<
    {
      grantLakehouseIcebergTableAccess: {
        message: string
      }
      errors: {
        message: string
        extensions?: {
          code?: string
        }
      }[]
    },
    Error,
    {
      uniName: string
      productKey: string
      tableName: string
      grantedEmail: string
      region: string
    }
  >({
    mutationFn: async (values) => {
      const { uniName, productKey, tableName, grantedEmail, region } = values
      return api.grantLakehouseIcebergTableAccess({ uniName, productKey, tableName, grantedEmail, region })
    },
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`Failed to send invitation: ${response?.errors?.[0]?.message}`)
        return
      }
      closeShareDataProductModal()
      notify.success('Invite sent successfully!')
    },
    onError: (error) => {
      console.error('Error sharing data product:', error)
      notify.error('Failed to send invite.')
    },
  })

  type SharingFormValues = {
    product: string
    region: string
    inviteeEmail: string
  }

  const sharingForm = useForm<SharingFormValues>({
    onSubmit: async ({ value }) => {
      console.log(value)
      const matchingProduct = shareAppConfig.products.find((p) => p.key === value.product)
      if (!matchingProduct) {
        return
      }
      const tableName = matchingProduct.tableDefinitions[0].tableName // Assuming single table for now
      await shareDataProduct.mutateAsync({
        uniName: uni.name,
        productKey: value.product,
        tableName,
        grantedEmail: value.inviteeEmail,
        region: value.region,
      })
    },
    defaultValues: {
      product: selectedProductToShare || shareAppConfig.products[0].key,
      region: '',
      inviteeEmail: '',
    },
  })
  const openShareDataProductModal = (index: number) => {
    setSelectedProductToShare(shareAppConfig.products[index].key)
    sharingForm.reset()
    setStatus(Status.ShareModalOpen)
  }
  const closeShareDataProductModal = () => {
    setSelectedProductToShare(null)
    setStatus(Status.Idle)
  }
  const product = sharingForm.useStore((state) => state.values.product)
  const selectedProduct = shareAppConfig.products.find((p) => p.key === product)

  let statusPill =
    uni.status && uni.status !== 'RUNNING' ? <StatusPill status={uni.status} data-testid='uni-status' /> : null

  if (!statusPill && anyShareAppsDeploying) {
    statusPill = <StatusPill status={ShareAppStatus.Deploying} data-testid='uni-status' />
  }
  return (
    <ContentWindow>
      {sourceType === 'SNOWFLAKE' &&
        !lakehouseNotReady &&
        !getSnowflakeConnectionStatus.isLoading &&
        !isSnowflakeConnectionValid && <ConnectionBanner className='m-6 mb-0' uni={uni} />}
      <div className='flex flex-grow flex-col gap-4'>
        <PageHeader
          title={uniAlias!}
          titleAfter={statusPill}
          actions={
            <>
              <Button
                kind='secondary'
                icon='plus-m'
                iconSize={14}
                to='../edit-products'
                disabled={lakehouseNotReady}
                className='mr-2'
              >
                Add data product
              </Button>
              <Button
                kind='secondary'
                icon='sidenav-settings-gear'
                iconSize={14}
                to='../distro-settings'
                disabled={lakehouseNotReady}
              >
                Settings
              </Button>
            </>
          }
          testid='uni-name-header'
        />
        <div className='flex gap-6 p-6'>
          <div className='w-1/3'>
            <Card className='w-full'>
              <div className='flex flex-col gap-4'>
                <div className='flex justify-between pb-2 text-sm font-bold'>
                  <div>Source</div>
                  <div>{sourceNodeRegion}</div>
                </div>
                <CardRule />
                <div className='flex flex-col gap-2 pb-2'>
                  <div className='flex flex-col gap-4'>
                    <div className='flex items-center justify-between'>
                      <SectionHeading>Data source</SectionHeading>
                    </div>
                    {ingestionShareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion && (
                      <div className='flex items-center gap-4'>
                        <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-10' />
                        <div className='flex flex-col gap-0.5'>
                          <div className='font-bold'>Snowflake</div>
                          <div className='text-neutral-8 text-xs'>{sourceTableName}</div>
                        </div>
                      </div>
                    )}
                    {ingestionShareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion && (
                      <div className='flex items-center gap-4'>
                        <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-10' />
                        <div className='flex flex-col gap-0.5'>
                          <div className='font-bold'>Cloudera</div>
                          <div className='text-neutral-8 text-xs'>{sourceTableName}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className='border-neutral-2 my-4 border' />
                  <div className='flex flex-col gap-4'>
                    <SectionHeading>Total data products</SectionHeading>
                    <BigMetric>{shareAppConfig.products.length}</BigMetric>
                  </div>
                  <hr className='border-neutral-2 my-4 border' />
                  <div className='flex flex-col gap-4'>
                    <SectionHeading>Total regions</SectionHeading>
                    <BigMetric>{totalRegions}</BigMetric>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className='flex w-2/3 flex-col gap-4'>
            {shareAppConfig.products.map((product, index) => (
              <DataProductCard
                key={product.key}
                uni={uni}
                product={product}
                productIndex={index}
                setShareDataProduct={openShareDataProductModal}
                lakehouseJobsQueryResult={lakehouseJobsQuery}
                lakehouseNotReady={lakehouseNotReady}
              />
            ))}
          </div>
        </div>
      </div>
      <BasicModal
        className='!max-w-2xl lg:!max-w-3xl'
        title='Share data product'
        isOpen={status === Status.ShareModalOpen}
        onClose={closeShareDataProductModal}
        buttons={
          <div className='flex gap-4'>
            <Button kind='secondary' onClick={closeShareDataProductModal}>
              Cancel
            </Button>
            <sharingForm.Subscribe selector={(state) => state.isSubmitting}>
              {(isSubmitting) => {
                return (
                  <Button type='submit' form='sharingForm' data-testid='invite-submit-button' disabled={isSubmitting}>
                    {isSubmitting && <Icon isSpinning name='refresh' size={'xxs'} className='mr-1' />}
                    Send invite
                  </Button>
                )
              }}
            </sharingForm.Subscribe>
          </div>
        }
      >
        <Form<SharingFormValues> className='flex-1 space-y-4 overflow-auto p-4' form={sharingForm} id='sharingForm'>
          <div className='flex flex-col gap-3'>
            <ListboxField
              form={sharingForm}
              name='product'
              className='min-w-72'
              label='Select a product'
              options={shareAppConfig.products.map((product) => ({
                value: product.key,
                label: product.name,
              }))}
              validators={isRequiredOnBlur}
            />
            <ListboxField
              form={sharingForm}
              name='region'
              className='min-w-72'
              label='Select a region'
              validators={isRequiredOnBlur}
              options={
                AWS_REGIONS.map(({ value, displayAlt }) => ({
                  value,
                  label: displayAlt,
                  disabled: !selectedProduct?.regions.includes(value),
                  description: selectedProduct?.regions.includes(value)
                    ? null
                    : 'Product is not enabled for this region.',
                })).sort((a, b) => {
                  if (a.disabled && !b.disabled) {
                    return 1
                  }
                  if (!a.disabled && b.disabled) {
                    return -1
                  }
                  return 0
                }) as ListOption[]
              }
            />
            <TextField
              form={sharingForm}
              name='inviteeEmail'
              label='Invitee email'
              description='Enter the email of the user you want to invite.'
              validators={isRequiredOnBlur}
            />
          </div>
        </Form>
      </BasicModal>
    </ContentWindow>
  )
}

const DataProductCard = ({
  uni,
  product,
  productIndex,
  setShareDataProduct,
  lakehouseJobsQueryResult,
  lakehouseNotReady,
}: {
  uni: Uni
  product: LakehouseDataProductInput
  productIndex: number
  setShareDataProduct: (index: number) => void
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
  lakehouseNotReady: boolean
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showSyncModal, setShowSyncModal] = useState(false)
  const jobs = lakehouseJobsQueryResult.data?.getLakehouseJobs?.jobs?.[product.key]
  const dataProduct = { ...product, ...product.tableDefinitions?.[0] } as LakehouseProduct
  const api = useApi()

  const { refetch: refetchLakehouseJobs } = lakehouseJobsQueryResult

  const lakehouseIngestionSFNMutation = useMutation({
    mutationFn: ({ uniName, dataProductKey }: { uniName: string; dataProductKey: string }) =>
      api.invokeLakehouseIngestionSFN({ uniName, dataProductKey }),

    onError: (error) => notify.error(`Error syncing data product: ${error}`),
    onSuccess: (response) => {
      if (response.message) {
        notify.error(`Error syncing data product: ${response.message}`)
        return
      }
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.invokeLakehouseIngestionSFN) {
        notify.error('Error syncing data product')
        return
      }
      notify.success(`Successfully initiated sync for: ${product.name}`)
      setShowSyncModal(false)
      refetchLakehouseJobs()
    },
  })

  return (
    <Card key={product.key} className='w-full' padding='none'>
      <div className='flex w-full justify-between gap-6 p-6'>
        <div className='flex flex-grow flex-col gap-4'>
          <div className='flex items-center gap-6'>
            <img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />
            <div className='flex flex-col gap-1'>
              {lakehouseNotReady ? (
                <div className='text-lg font-bold text-gray-400'>{product.name}</div>
              ) : (
                <Link to={`../product/${product.name}`} className='text-link text-lg font-bold'>
                  {product.name}
                </Link>
              )}
              <div className='text-neutral-10 text-balance text-xs'>
                {product.regions
                  .map((r) => AWS_REGIONS.find((region) => region.value === r)?.displayAlt || r)
                  .join(', ')}
              </div>
              <div className='mt-1'>
                <DataProductSyncStatus
                  product={product}
                  jobs={jobs}
                  lakehouseJobsQueryResult={lakehouseJobsQueryResult}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <ButtonIconAction
            icon={'refresh'}
            label='Sync data product'
            onClick={() => setShowSyncModal(true)}
            disabled={
              lakehouseNotReady ||
              jobs?.[0]?.source.status === 'RUNNING' ||
              product.regions.some((r) => jobs?.[0]?.regions[r]?.status === 'RUNNING')
            }
          />
          <ButtonIconAction
            icon={'share'}
            label='Share data product'
            onClick={() => {
              setShareDataProduct(productIndex)
            }}
            disabled={lakehouseNotReady}
          />
          <ButtonIconAction
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            label='Toggle sharing rule details'
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>
      {expanded ? (
        <>
          <CardRule />
          <ProductPolicies product={dataProduct} canEdit={true} lakehouseStatus={lakehouseNotReady} />
        </>
      ) : null}
      <ConfirmationModal
        isOpen={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={`Confirm sync for ${product?.name}`}
        onSubmit={() => lakehouseIngestionSFNMutation.mutate({ uniName: uni.name, dataProductKey: product.key })}
        isSubmitting={lakehouseIngestionSFNMutation.isPending}
        actionButtonType={`primary`}
        actionButtonText={`Sync`}
        confirmationText={'sync'}
      >
        <div className='grid gap-4'>
          <p>
            Immediately begin syncing the latest data from your configured source to the <strong>{product.name}</strong>{' '}
            data product.
          </p>
          <Alert className='text-sm italic text-gray-500'>
            Note: Once started, the sync process cannot be cancelled or undone.
          </Alert>
        </div>
      </ConfirmationModal>
    </Card>
  )
}

const DataProductSyncStatus = ({
  product,
  jobs,
  lakehouseJobsQueryResult,
}: {
  product: LakehouseDataProductInput
  jobs?: JobsByDataProductKey[string]
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
}) => {
  const lastSync = jobs?.[0]

  const isLastSyncRunning =
    lastSync?.source.status === 'RUNNING' || product.regions.some((r) => lastSync?.regions[r]?.status === 'RUNNING')

  const isLastSyncSuccess =
    !isLastSyncRunning &&
    lastSync?.source.status === 'SUCCEEDED' &&
    product.regions.every((r) => lastSync.regions[r]?.status === 'SUCCEEDED')

  let lastSuccessfulSync = lastSync
  if (!isLastSyncSuccess) {
    lastSuccessfulSync = jobs?.find((job) => {
      return job.source.status === 'SUCCEEDED' && product.regions.every((r) => job.regions[r]?.status === 'SUCCEEDED')
    })
  }

  const lastSuccessfulSyncTime = lastSuccessfulSync
    ? Object.values(lastSuccessfulSync?.regions).reduce((acc, region) => {
        if (dayjs(region.stopTime).isAfter(dayjs(acc))) {
          return region.stopTime
        }
        return acc
      }, lastSuccessfulSync.source.stopTime)
    : null

  const syncfailureTime =
    lastSync && !isLastSyncSuccess
      ? Object.values(lastSync?.regions).reduce((acc, region) => {
          if (failureStatuses.includes(region.status) && dayjs(region.stopTime).isBefore(dayjs(acc))) {
            return region.stopTime
          }
          return acc
        }, lastSync.source.stopTime)
      : null

  return (
    <div className='text-neutral-10 flex flex-col gap-1 text-xs'>
      {lakehouseJobsQueryResult.isLoading ? (
        <>
          <div className='flex gap-1'>
            <div>Last successful sync: </div>
            <div className='text-gray-500'>loading...</div>
          </div>
          <div className='flex gap-1'>
            <span>Last sync status: </span>
            <span className='text-gray-500'>loading...</span>
          </div>
        </>
      ) : (
        <>
          <div className='flex gap-1'>
            <div>Last successful sync: </div>
            {lastSuccessfulSyncTime ? (
              <HoverableTooltip
                align='start'
                content={
                  <span className='font-semibold'>
                    Sync succeeded on {dayjs(lastSuccessfulSyncTime).toDate().toLocaleString()}
                  </span>
                }
                side='bottom'
                sideOffset={10}
                isModern
              >
                <span className='font-semibold'>{dayjs(lastSuccessfulSyncTime).fromNow()}</span>
              </HoverableTooltip>
            ) : (
              <div className='font-semibold'>Never</div>
            )}
          </div>
          {lastSync && isLastSyncSuccess ? (
            <div className='text-success-13 font-semibold'>
              <span>Last sync status: </span>
              <span className='text-success-12 font-bold'>All regions synced</span>
            </div>
          ) : null}
          {lastSync && isLastSyncRunning ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1'>
                  <div className='mb-2 font-semibold'>Sync progress</div>
                  <div className='flex justify-between gap-8'>
                    <div>Syncing latest data from source:</div>
                    {lastSync?.source.status === 'RUNNING' ? (
                      <span className='text-information-10 font-semibold'>Syncing...</span>
                    ) : (
                      <span className='text-success-13 font-semibold'>Complete!</span>
                    )}
                  </div>
                  {Object.entries(lastSync?.regions).map(([region, status]) => {
                    return (
                      <div key={region} className='flex justify-between gap-8 text-wrap'>
                        <div>
                          Syncing your data product to <strong>{region}</strong>:
                        </div>
                        {status.status === 'RUNNING' ? (
                          <span className='text-information-10 font-semibold'>Syncing...</span>
                        ) : (
                          <span className='text-success-13 font-semibold'>Complete!</span>
                        )}
                      </div>
                    )
                  })}
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-information-13 animate-pulse font-semibold'>
                <span>Last sync status: </span>
                <span className='text-information-12 font-bold'>Syncing...</span>
              </div>
            </HoverableTooltip>
          ) : null}
          {lastSync && !isLastSyncRunning && !isLastSyncSuccess ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1 text-wrap'>
                  {syncfailureTime ? (
                    <div className='mb-2 font-semibold'>Sync failed {dayjs(syncfailureTime).fromNow()}</div>
                  ) : null}
                  {lastSync?.source.error && (
                    <div>
                      {/* we could parse errors from source.error and show certain messages if any prove to be useful */}
                      An error occurred during our attempt to pull from your data source.
                    </div>
                  )}
                  {Object.entries(lastSync?.regions).map(([region, status]) => {
                    if (failureStatuses.includes(status.status)) {
                      return (
                        <div key={region}>
                          A problem occurred during our attempt to update your data product in <strong>{region}</strong>
                          .
                        </div>
                      )
                    }
                  })}
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-error-8'>
                <span>Last sync status: </span>
                {lastSync?.source.error ? (
                  <span className='text-error-8 font-bold'>Source sync failed</span>
                ) : (
                  <span className='text-error-8 font-bold'>1 or more regions failed</span>
                )}
              </div>
            </HoverableTooltip>
          ) : null}
        </>
      )}
    </div>
  )
}

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <h5 className='text-neutral-9 text-xs font-bold'>{children}</h5>
)

const BigMetric = ({ children }: { children: React.ReactNode }) => <div className='text-2xl font-bold'>{children}</div>
